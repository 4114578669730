import {type IOrderData, ServiceCategoryTypes} from "../types";
import {IOnlineOrderServices} from "./../thunks/fetchOnlineOrderServices";
import {setCategorySelection} from "./setCategorySelection";

type RemoveUnavailableServicesType = (data: {
  state: IOrderData;
  payload: IOnlineOrderServices;
}) => void;

export const removeUnavailableServices: RemoveUnavailableServicesType = ({
  state,
  payload,
}) => {
  const selectedServices = state.orderBuilder.services.customerSelectedServices;

  if (!selectedServices) {
    return;
  }

  const availableServices = payload.services;

  const newSelectedServices = selectedServices.filter((service) =>
    availableServices.find(
      (availableService) =>
        availableService.id === service.id &&
        availableService?.prices[0]?.id === service?.prices[0]?.id
    )
  );

  state.orderBuilder.services.customerSelectedServices = newSelectedServices;

  if (!newSelectedServices.length) {
    setCategorySelection({
      state,
      newValue: false,
      categoryType: ServiceCategoryTypes.LAUNDRY,
    });
  }
};
